import { createAsyncThunk } from '@reduxjs/toolkit';
import CLIENT from '../../utils/CLIENT';
import { TimeHelper } from '../../utils';
import { IGetSlotsByDatePayload, IGetSlotsByDatesPayload, IGetSwapSlotsPayload } from './types';
import { SwapSlot } from '../../types';

interface FulfillValue {
  data: any;
  timestamp: number;
}

interface RejectValue {
  error: string;
  timestamp: number;
}

const fetchSlots = async (payload: IGetSlotsByDatesPayload) => {
  const query: string[] = [
    `timestamp=${payload.timestamp}`,
    `days=${payload.dates}`,
  ];
  if (payload.serviceIds.length) {
    query.push(`serviceIds=${payload.serviceIds}`);
  }

  const { data: responseData } = await CLIENT({
    url: `/slots?${query.join('&')}`,
    method: 'GET',
  });

  const slots = {};
  const slotDays = Object.keys(responseData.data.slots);
  slotDays.forEach((slotday) => {
    if (responseData.data.slots[slotday]) {
      slots[slotday] = {};
      const employeeIds = Object.keys(responseData.data.slots[slotday]);
      employeeIds.forEach((employee) => {
        slots[slotday][employee] = responseData.data.slots[slotday][employee].map((slot) => {
          const dateTime = TimeHelper.dateAndTimeToDateAndTimeTz(slotday, slot.time, payload.timezone);

          return {
            ...slot,
            time: TimeHelper.getHHmmTimeFromMinutes(dateTime.time),
          };
        });
      });
    }
  });

  return { data: { ...responseData.data, slots }, timestamp: responseData.timestamp };
};

export const getSlotsByDate = createAsyncThunk<FulfillValue, IGetSlotsByDatePayload, { rejectValue: RejectValue }>(
  'slots/get-slots-by-date',
  async (payload, thunkAPI) => {
    try {
      return await fetchSlots({
        serviceIds: payload.serviceIds,
        dates: [payload.date],
        timestamp: payload.timestamp,
        timezone: payload.timezone,
      });
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return thunkAPI.rejectWithValue({
        error: error.message,
        timestamp: payload.timestamp,
      });
    }
  },
);

export const getSlots = createAsyncThunk<FulfillValue, IGetSlotsByDatesPayload, { rejectValue: RejectValue }>(
  'slots/get-slots',
  async (payload, thunkAPI) => {
    try {
      return await fetchSlots(payload);
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return thunkAPI.rejectWithValue({
        error: error.message,
        timestamp: payload.timestamp,
      });
    }
  },
);

export const getSwapSlots = createAsyncThunk<FulfillValue, IGetSwapSlotsPayload, { rejectValue: RejectValue }>(
  'slots/get-swap-slots',
  async (payload, thunkAPI) => {
    try {
      const query: string[] = [
        `date=${payload.date}`,
        `employeeId=${payload.employeeId}`,
        `serviceIds=${payload.serviceIds}`,
        `timestamp=${payload.timestamp}`,
      ];

      const response = await CLIENT({
        url: `/slots/swap-slots?${query.join('&')}`,
        method: 'GET',
      });

      return {
        ...response.data,
        data: response.data.data.map((swapSlot: SwapSlot) => {
          const dateTime = TimeHelper.dateAndTimeToDateAndTimeTz(payload.date, swapSlot.time, payload.timezone);

          return ({ ...swapSlot, time: dateTime.time });
        }),
      };
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return thunkAPI.rejectWithValue({
        error: error.message,
        timestamp: payload.timestamp,
      });
    }
  },
);
