import dayjs, { Dayjs } from 'dayjs';

import { cacheInvocation } from './cacheInvocation';

/*
 * Time helper functions to convert data formats
 * */

type DateValue = number | Date | string;

type DateTime = { date: string; time: number };

export class TimeHelper {
  static toDayjs(date: DateValue, timeZone = 'Europe/Berlin'): Dayjs {
    return dayjs.tz(date, timeZone);
  }

  static toUtcDayjs(date: DateValue): Dayjs {
    return dayjs.utc(date);
  }

  static toStandardFormat(dayjsDate: Dayjs) {
    return dayjsDate.format('YYYY-MM-DD');
  }

  static toDayFormat(dayjsDate: Dayjs) {
    return dayjsDate.format('D');
  }

  static convertDateAndTimeToDayjs(obj: { time: number; date: string }, timeZone?: string) {
    const dateTime = TimeHelper.toDayjs(obj.date, timeZone);
    return dateTime.add(obj.time, 'minutes');
  }

  static convertToMinutesFromDayStart(date: Dayjs) {
    const startOfDay = date.startOf('day');

    return date.diff(startOfDay, 'minutes');
  }

  static convertUtcHoursToTimezoneHours(obj: { from?: number; to?: number; date?: string }, timeZone: string) {
    if (!obj.from || !obj.to) {
      return obj;
    }
    const baseDate = TimeHelper.toUtcDayjs(obj.date || Date.now())
      .startOf('day')
      .valueOf();
    const date = TimeHelper.toDayjs(baseDate, timeZone);
    const from = date.clone().add(obj.from, 'minutes');
    const to = date.clone().add(obj.to, 'minutes');
    return {
      ...obj,
      from: TimeHelper.convertToMinutesFromDayStart(from),
      to: TimeHelper.convertToMinutesFromDayStart(to),
    };
  }

  static getTimeByMinutes(_minutes: number) {
    const hours = Math.floor(_minutes / 60);
    const minutes = _minutes % 60;
    const hoursStr = hours < 10 ? `0${hours}` : hours;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hoursStr}:${minutesStr}`;
  }

  static getTimeByMinutesWithTimeZone(_minutes: number, timeZone?: string) {
    const dateBase = TimeHelper.toUtcDayjs(Date.now()).startOf('day').add(_minutes, 'minutes').valueOf();
    return TimeHelper.toDayjs(dateBase, timeZone).format('HH:mm');
  }

  static get12HTimeByMinutes(_minutes: number) {
    const hours = Math.floor(_minutes / 60);
    const minutes = _minutes % 60;
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hoursStr: string | number = hours % 12 || 12; // the hour '0' should be '12'
    hoursStr = hoursStr < 10 ? `0${hoursStr}` : hoursStr;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hoursStr}:${minutesStr} ${ampm}`;
  }

  static formatDayOfYear(date: string | number): string {
    // 02. Dezember 2022
    return dayjs(Number(date)).tz().format('DD[.] MMMM YYYY');
  }

  static getTimeByUnix = (date: string | number, with12hours?: boolean) => {
    const time = dayjs(Number(date)).tz();
    if (with12hours) {
      return time.format('hh:mm A');
    }

    return time.format('HH:mm');
  };

  static convertMinutesToMilliseconds(minutes: number) {
    return minutes * 60 * 1000;
  }

  static convertMinutesToSeconds(minutes: number) {
    return minutes * 60;
  }

  static convertMillisecondsToSeconds(milliseconds: number) {
    return milliseconds / 1000;
  }

  static convertSecondsToMilliseconds(seconds: number) {
    return seconds * 1000;
  }

  static convertMillisecondsToMinutes(milliseconds: number) {
    return milliseconds / (60 * 1000);
  }

  static convertUnixTimeToMinutesFromDayStart(unix: number) {
    const startOfDay = dayjs.tz(unix).startOf('day');
    const unixTime = dayjs.tz(unix);

    return unixTime.diff(startOfDay, 'minutes');
  }

  static convertMinutesFromDayStartToMilliseconds(minutes: number) {
    return dayjs.tz(Date.now()).startOf('day').add(minutes, 'minute').valueOf();
  }

  static getCurrentTimeInMilliSeconds() {
    return dayjs.tz(new Date()).utcOffset(0).valueOf();
  }

  static getStartOfDayTimeInMilliSeconds() {
    return dayjs.tz(new Date()).startOf('day').valueOf();
  }

  static getPastMinutesFrom(unix: number) {
    const startTime = dayjs.tz(unix);
    const now = dayjs();

    return now.diff(startTime, 'minutes');
  }

  static getMinutesFromHHmmTimeString = (time: string) => {
    const hours = Number(time.substring(0, 2));
    const minutes = Number(time.substring(3));
    return hours * 60 + minutes;
  };

  public static getHHmmTimeFromMinutes = (value: number) => {
    const hours = Math.floor(value / 60);
    const hoursWithZero = hours < 10 ? `0${hours}` : hours.toString();
    const minutes = Math.floor(value % 60);
    const minutesWithZero = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `${hoursWithZero}:${minutesWithZero}`;
  };

  public static getMinutesFromDayStart(nowDjs: Dayjs = dayjs.tz(Date.now())) {
    return nowDjs.diff(nowDjs.startOf('day'), 'minutes');
  }

  public static getMinutesFromDayStartThrottled = cacheInvocation(this.getMinutesFromDayStart, 1000 * 60 * 1);

  public static getMinutesDiff(from: number, to: number): number {
    return Math.floor(TimeHelper.convertMillisecondsToMinutes(to - from));
  }

  public static getCurrentDate(): Date {
    return dayjs.tz(new Date()).toDate();
  }

  public static getIsoWeekRange(date: number) {
    const currentDate = dayjs.tz(date);

    const startOfWeek = currentDate.clone().startOf('isoWeek');
    const endOfWeek = currentDate.clone().endOf('isoWeek');

    return [startOfWeek, endOfWeek];
  }

  public static getWeekdays(date: Dayjs) {
    const days: Dayjs[] = [];
    const weekStart = date.clone().startOf('isoWeek');

    for (let i = 0; i <= 6; i += 1) {
      days.push(dayjs(weekStart).add(i, 'days'));
    }

    return days;
  }

  /**
   * Get Dayjs instance with exact date and time to get date with exact time from day start
   *
   * @param dateString - date string in format YYYY-MM-DD
   * @param minutesFromDayStart - minutes from day start
   * @returns Dayjs instance
   */
  public static dateTimeToDayjs(dateString: string, minutesFromDayStart: number = 0): Dayjs {
    return dayjs(dateString).set('minutes', minutesFromDayStart);
  }

  public static timestampToDateAndTimeTz(timestamp: number, timeZone: string): DateTime {
    const dateDayjs = TimeHelper.toDayjs(timestamp, timeZone);
    const date = TimeHelper.toStandardFormat(dateDayjs);
    const time = TimeHelper.convertToMinutesFromDayStart(dateDayjs);

    return { date, time };
  }

  public static dateAndTimeToDateAndTimeTz(dateString: string, minutesFromDayStart: number, timezone: string): DateTime {
    const timestamp = new Date(dateString).valueOf() + minutesFromDayStart * 60 * 1000;

    return TimeHelper.timestampToDateAndTimeTz(timestamp, timezone);
  }

  public static getDateStringTz(date: DateValue, timezone: string) {
    return TimeHelper.toStandardFormat(TimeHelper.toDayjs(date, timezone).startOf('day'));
  }

  public static hoursOffsetBetweenDifferentTimezones(timeZone1: string, timeZone2: string, date?: Date) {
    const tempDate = date ?? new Date();

    const date1 = dayjs(tempDate).tz(timeZone1);
    const date2 = dayjs(tempDate).tz(timeZone2);

    return (date1.utcOffset() - date2.utcOffset()) / 60;
  }

  public static hoursOffsetBetwenLocalAndTimezone(timeZone: string, date?: Date) {
    return TimeHelper.hoursOffsetBetweenDifferentTimezones(dayjs.tz.guess(), timeZone, date);
  }

  public static getNowDate(timezone: string): Date {
    const dateTimeString = TimeHelper.toDayjs(new Date(), timezone).format('YYYY-MM-DD HH:mm');
    return new Date(dateTimeString);
  }
}
