import { throttle } from 'lodash';

type DocumentsHandler<SetDocumentType, RemoveDocumentType> = (bulkData: {
  set: SetDocumentType[],
  remove: RemoveDocumentType[]
}) => void;

/**
 * This class is used to collect and proceed documents in a single thread
 */
export class DocumentsQueue<SetDocumentType, RemoveDocumentType> {
  protected queueForSet: SetDocumentType[] = [];

  protected queueForRemove: RemoveDocumentType[] = [];

  protected throttledNext: () => void;

  constructor(protected documentsHandler: DocumentsHandler<SetDocumentType, RemoveDocumentType>) {
    this.throttledNext = throttle(this.next, 200);
  }

  protected next() {
    const removedDocuments = this.queueForRemove.splice(0, this.queueForRemove.length);
    const updatedDocuments = this.queueForSet.splice(0, this.queueForSet.length);

    this.documentsHandler({
      set: updatedDocuments,
      remove: removedDocuments,
    });
  }

  public addDocumentToSet(document: SetDocumentType) {
    this.queueForSet.push(document);
    this.throttledNext();
  }

  public addDocumentToRemove(documentId: RemoveDocumentType) {
    this.queueForRemove.push(documentId);
    this.throttledNext();
  }

  public addBulkDocuments(data: { set: SetDocumentType[], remove: RemoveDocumentType[] }) {
    this.queueForSet.push(...data.set);
    this.queueForRemove.push(...data.remove);

    this.throttledNext();
  }
}
