import React from 'react';
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ObjectSchema } from 'yup';

import { CustomerNameInput, GenderButtonGroup, OutlinedInput } from '../subcomponents';
import { isRequired } from '../util/isRequired';

type Props = {
  size?: 'default' | 'small';
  isDisabled?: boolean;
  schema: ObjectSchema<any>;
};

function AppointmentFormCustomerSectionComponent({ size, isDisabled, schema }: Props) {
  const form = useFormContext();
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          name="gender"
          render={({ field }) => (
            <GenderButtonGroup
              disabled={!!isDisabled}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={size === 'small' ? 12 : 6}>
        <Controller
          control={form.control}
          name="lastName"
          render={({ field, fieldState }) => (
            <CustomerNameInput
              dataTestId="lastname-input"
              placeholder={intl.formatMessage({ id: 'appointment.lastName' })}
              disabled={!!isDisabled}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={isRequired(schema, field.name)}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>

      <Grid item xs={size === 'small' ? 12 : 6}>
        <Controller
          control={form.control}
          name="firstName"
          render={({ field, fieldState }) => (
            <CustomerNameInput
              dataTestId="firstname-input"
              placeholder={intl.formatMessage({ id: 'appointment.firstName' })}
              disabled={!!isDisabled}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={isRequired(schema, field.name)}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <CustomerNameInput
              dataTestId="email-input"
              placeholder={intl.formatMessage({ id: 'appointment.email' })}
              disabled={!!isDisabled}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={isRequired(schema, field.name)}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={form.control}
          name="phoneNumber"
          render={({ field, fieldState }) => (
            <OutlinedInput
              dataTestId="phonenumber-input"
              placeholder={intl.formatMessage({ id: 'appointment.phoneNumber' })}
              disabled={!!isDisabled}
              value={field.value || ''}
              type="tel"
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={isRequired(schema, field.name)}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={form.control}
          name="note"
          render={({ field, fieldState }) => (
            <OutlinedInput
              dataTestId="customer-notes-input"
              placeholder={intl.formatMessage({ id: 'appointment.notes' })}
              disabled={!!isDisabled}
              value={field.value || ''}
              multiline
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={isRequired(schema, field.name)}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

AppointmentFormCustomerSectionComponent.defaultProps = {
  size: 'default',
  isDisabled: false,
};

export const AppointmentFormCustomerSection = React.memo(AppointmentFormCustomerSectionComponent);
