import Dialog from '@mui/material/Dialog';
import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';

type Props = {
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
};

export const ResyncDialog: React.FC<Props> = (
  {
    open,
    title,
    content,
    onClose,
    onSubmit,
    submitting,
  },
) => {
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="abbort-button"
          autoFocus
          onClick={onClose}
        >
          { intl.formatMessage({ id: 'common.abort' }) }
        </Button>
        <LoadingButton
          data-testid="confirm-button"
          onClick={onSubmit}
          loading={submitting}
        >
          { intl.formatMessage({ id: 'common.confirm' }) }
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
