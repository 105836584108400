import React, { useEffect, useRef, useState } from 'react';
import { NoticeStack } from '@linetweet/linetweet-ui';
import { shallowEqual } from 'react-redux';
import { ConnectionStatusNotice } from './components/ConnectionStatusNoticeComponent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppIsIsDisconnectedDialog } from './components/AppIsIsDisconnectedDialog';
import { onAppIsStopped } from '../../store/global/slice';

const ALLOWED_TIME_WITHOUT_CONNECTION = 110000; // 1min 50sec

export function ApplicationConnectionStatus() {
  const { disconnectionTime } = useAppSelector((state) => state.global, shallowEqual);
  const previousDisconnectionTime = useRef(0);

  const dispatch = useAppDispatch();

  const [noticeStatus, setNoticeStatus] = useState<{
    isConnectionOfflineOpen: boolean,
    isConnectionOnlineOpen: boolean,
    isAppCompletelyDisconnected?: boolean
  }>({
    isConnectionOfflineOpen: false,
    isConnectionOnlineOpen: false,
  });

  const appIsTemporaryOffline = () => {
    setNoticeStatus({
      isConnectionOfflineOpen: true,
      isConnectionOnlineOpen: false,
    });
  };

  const appIsOnlineAgain = () => {
    setNoticeStatus({
      isConnectionOfflineOpen: false,
      isConnectionOnlineOpen: true,
    });
  };

  const appIsCompletellyDisconnected = () => {
    setNoticeStatus({
      isAppCompletelyDisconnected: true,
      isConnectionOfflineOpen: false,
      isConnectionOnlineOpen: false,
    });
    dispatch(onAppIsStopped());
  };

  const onCloseConnectionStatusNotice = () => {
    setNoticeStatus({
      isConnectionOfflineOpen: false,
      isConnectionOnlineOpen: false,
    });
  };

  const reloadTheApp = () => {
    window.location.reload();
  };

  useEffect(() => {
    let cleanup;

    if (disconnectionTime) {
      appIsTemporaryOffline();

      const timeoutId = setTimeout(() => {
        appIsCompletellyDisconnected();
      }, ALLOWED_TIME_WITHOUT_CONNECTION);

      cleanup = () => clearTimeout(timeoutId);
    }
    if (previousDisconnectionTime.current) {
      appIsOnlineAgain();
    }

    previousDisconnectionTime.current = disconnectionTime;

    return cleanup;
  }, [disconnectionTime]);

  return (
    <>
      {noticeStatus?.isAppCompletelyDisconnected && <AppIsIsDisconnectedDialog onClick={reloadTheApp} />}
      <NoticeStack>
        <ConnectionStatusNotice
          isConnectionOfflineOpen={noticeStatus.isConnectionOfflineOpen}
          isConnectionOnlineOpen={noticeStatus.isConnectionOnlineOpen}
          onClose={onCloseConnectionStatusNotice}
        />
      </NoticeStack>
    </>
  );
}
