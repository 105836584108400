import * as yup from 'yup';

export const blockerFormSchema = yup.object().shape({
  date: yup
    .string()
    .required(),
  note: yup
    .string()
    .nullable()
    .defined(),
  employee: yup
    .string()
    .required(),
  title: yup
    .string()
    .nullable()
    .required(),
  from: yup
    .number()
    .nullable()
    .required(),
  to: yup
    .number()
    .nullable()
    .required(),
});
