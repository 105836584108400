import React, { useMemo } from 'react';
import { Avatar, Box, Stack, Typography, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { parseEmployeeNameWithEmoji } from 'utils';
import Chip from '../subcomponents/ChipSubcomponent';
import { CalendarResource } from '../types';
import { CalendarEventTypeEnum } from '../../../types';
import { ServiceIcon } from '../../commons';

import styles from './ResourceHeader.module.scss';

const eventNames = {
  [CalendarEventTypeEnum.APPOINTMENT]: 'calendar.appointments',
  [CalendarEventTypeEnum.BLOCKER]: 'calendar.blockers',
  [CalendarEventTypeEnum.BREAK]: 'calendar.breaks',
  [CalendarEventTypeEnum.TASK]: 'calendar.tasks',
  [CalendarEventTypeEnum.SLOT]: 'calendar.slots',
  [CalendarEventTypeEnum.WALKIN]: 'calendar.walkin',
};

function getEventTypeName(type: string) {
  // todo translations should be used when it will be ready
  return eventNames[type] || type;
}

type EmployeeResourceProps = {
  label: string;
  resource: CalendarResource;
};

function EmployeeResource(props: EmployeeResourceProps) {
  const intl = useIntl();
  const { label, resource } = props;
  const { shifts, weekDay, services } = resource;

  const workingInterval = useMemo(() => {
    if (!shifts) {
      return [];
    }
    const dayOfWork = shifts.find((shift) => weekDay === shift.weekDay);
    if (!dayOfWork) {
      return [];
    }
    return [dayOfWork.from, dayOfWork.to];
  }, [shifts, weekDay]);

  const nameValues = useMemo(() => parseEmployeeNameWithEmoji(label), [label]);

  return (
    <Stack
      data-testid={`employee-resource-header-${resource?.id}`}
      direction="column"
      justifyContent="space-between"
      alignItems="start"
      className={styles.container}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Avatar className={styles.avatar}>
          <Typography className={styles.avatarText}>{nameValues.name}</Typography>
        </Avatar>
        <Typography className={styles.label}>{nameValues.fullName}</Typography>
      </Stack>
      {workingInterval.length === 2 && (
        <Typography
          className={styles.workingHours}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: 'calendar.employeeWorkingHours' },
              { from: `<b>${workingInterval[0]}</b>`, to: `<b>${workingInterval[1]}</b>` },
            ),
          }}
        />
      )}
      {!!services.length && (
        <Stack direction="row" className={styles.services} spacing={0.5}>
          {services.map((service) => (
            <Box className={styles.serviceWrapper} key={service.id}>
              <ServiceIcon icon={service.icon} />
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

function OverflowResource(props: EmployeeResourceProps) {
  const intl = useIntl();
  const eventTypes = props?.resource?.eventsStatistics ? Object.entries(props.resource.eventsStatistics).length : 0;
  const title = props.resource?.isEmployeeOverflow ? `${props.label}` : '';

  return (
    <div data-testid={`overflow-resource-header-${props.resource.id}`} className={styles.overflowContainer}>
      <div className={styles.overflowHeader}>{intl.formatMessage({ id: 'calendarOverview.overflowItems' })}</div>
      <div className={styles.overflowDescription}>
        <div>
          <Typography className={styles.overflowDescriptionTitle}>{title}</Typography>
        </div>
        <div className={clsx(styles.overflowSummary, { [styles.compactSummary]: eventTypes === 4 })}>
          {props?.resource?.eventsStatistics &&
            Object.entries(props?.resource?.eventsStatistics).map(([key, value]) => (
              <Chip key={key} label={`${intl.formatMessage({ id: getEventTypeName(key) })} - ${value}`} title={value.toString()} />
            ))}
        </div>
      </div>
    </div>
  );
}

function LoadingResource() {
  return (
    <Stack direction="column" justifyContent="space-between" alignItems="start" className={styles.container}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Skeleton animation="wave" variant="circular" width={30} height={30} />
        <Skeleton animation="wave" width={100} height={20} />
      </Stack>
      <Skeleton animation="wave" width={138} height={20} />

      <Stack direction="row" className={styles.services} spacing={0.5} />
    </Stack>
  );
}

export function ResourceHeader(props: EmployeeResourceProps) {
  const { resource } = props;
  if (resource.loading) {
    return <LoadingResource />;
  }
  if (resource.isOverflow) {
    return <OverflowResource {...props} />;
  }

  return <EmployeeResource {...props} />;
}
