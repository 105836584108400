import { createSlice } from '@reduxjs/toolkit';

export enum ConnectionType {
  Disconnected,
  InProgress,
  Connected,
}

interface InitialState {
  connectionStatus?: ConnectionType;
  disconnectionTime: number;
}

const initialState: InitialState = {
  disconnectionTime: 0,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    connect: (state) => {
      state.connectionStatus = ConnectionType.InProgress;
    },
    onAppIsOffline: (state) => {
      state.connectionStatus = ConnectionType.Disconnected;
      if (!state.disconnectionTime) {
        state.disconnectionTime = Date.now();
      }
    },
    onAppIsOnline: (state) => {
      state.connectionStatus = ConnectionType.Connected;
      state.disconnectionTime = 0;
    },
    onAppIsStopped: () => {
      // we listen for this reducer in the middleware
    },
  },
});

export const { connect, onAppIsOnline, onAppIsOffline, onAppIsStopped } = slice.actions;
export default slice.reducer;
