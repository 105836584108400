import { createSelector } from '@reduxjs/toolkit';
import { mapKeys, xor } from 'lodash';
import { RootState } from 'store';
import { Employee } from 'types';

export const selectSlotEmployeesByServiceIds = createSelector(
  [
    (state: RootState) => state.employees.employees,
    (state: RootState) => state.slots.data,
    (state: RootState, serviceIds: string[], date: string) => ({ serviceIds, date }),
  ],
  (
    employees,
    slotsData,
    { serviceIds, date },
  ): Employee[] => {
    const result: Employee[] = [];

    const slotsByEmployeeId = slotsData[serviceIds.sort().join(',')]?.[date] || {};

    const slotEmployeeIds = Object.keys(slotsByEmployeeId);

    if (!slotEmployeeIds.length || serviceIds.length === 0) {
      return employees;
    }

    const addedEmployeeIdsSet = new Set<string>();

    const employeesByIdMap = mapKeys(employees, (value) => value.id);

    for (let i = 0; i < slotEmployeeIds.length; i += 1) {
      const employeeId = slotEmployeeIds[i];
      const employee = employeesByIdMap[employeeId];

      if (employee) {
        const slots = slotsByEmployeeId[employeeId];

        if (slots && slots.length) {
          for (let slotIndex = 0; slotIndex < slots.length; slotIndex += 1) {
            const slot = slots[slotIndex];

            const difference = xor(slot.services, serviceIds);
            if (
              (!difference.length || serviceIds.length === 0) && !addedEmployeeIdsSet.has(employeeId)
            ) {
              addedEmployeeIdsSet.add(employeeId);
              result.push(employee);
            }
          }
        }
      }
    }

    return result;
  },
);

export const selectAvailableEmployees = createSelector([
  (state: RootState) => state.employees,
  (state: RootState, serviceIds: string[], date?: string) => ({ serviceIds, date }),
], ({ employees, shifts }, { serviceIds, date }): Employee[] => {
  if (!shifts || !date || !shifts[date] || employees.length < 1) {
    return [];
  }
  const employeeById = employees.reduce((acc, employee) => {
    acc[employee.id] = employee;
    return acc;
  }, {});
  return shifts[date].reduce((acc: Employee[], shift) => {
    const { employeeId, services } = shift;
    if (!employeeById[employeeId]) {
      return acc;
    }
    if (
      serviceIds.length > 0 &&
      serviceIds.find((serviceId) => !services.includes(serviceId))
    ) {
      return acc;
    }
    acc.push(employeeById[employeeId]);
    return acc;
  }, []);
});

export const getMissingShiftEmployeeIds = createSelector([
  (state: RootState) => state.employees,
  (state: RootState, date: string) => date,
], (employeesState, date): string[] => {
  const employeesByIdMap = employeesState.employees.reduce((acc, employee) => {
    acc[employee.id] = employee;
    return acc;
  }, {});

  const missingEmployeeIds: string[] = [];
  const dateShifts = employeesState.shifts[date] || [];
  for (let shiftIndex = 0; shiftIndex < dateShifts.length; shiftIndex += 1) {
    const shift = dateShifts[shiftIndex];
    if (!employeesByIdMap[shift.employeeId]) {
      missingEmployeeIds.push(shift.employeeId);
    }
  }

  return missingEmployeeIds;
});
