import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContentText, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
import styles from './AppIsIsDisconnectedDialog.module.scss';

type Props = {
  onClick: () => void;
};

export function AppIsIsDisconnectedDialog({ onClick }: Props) {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open
      className={styles.dialog}
    >
      <DialogTitle>
        {intl.formatMessage({ id: 'common.appIsDisconnectedTitle' })}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DialogContentText fontSize={16}>
          {intl.formatMessage({ id: 'common.appIsDisconnectedDescription' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onClick();
          }}
        >
          {intl.formatMessage({ id: 'common.appIsDisconnectedAction' })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
