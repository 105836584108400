import { Avatar, FormControl, FormHelperText, MenuItem, Select, Stack, Typography } from '@mui/material';
import { ArrowDown, Nullable } from '@linetweet/linetweet-ui';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import styles from './EmployeeSelectField.module.scss';

interface EmployeeSelectOption {
  id: string;
  label: string;
  iconLabel: string;
}

interface EmployeeSelectFieldProps {
  values: EmployeeSelectOption[];
  disabled?: boolean;
  value?: Nullable<string>;
  error?: boolean;
  helperText?: React.ReactNode;
  required?: boolean;
  onChange?: (value: Nullable<string>) => void;
  onBlur?: () => void;
}

function EmployeeSelectFieldComponent(
  props: EmployeeSelectFieldProps,
) {
  const {
    values,
    disabled,
    value: propsValue,
    error,
    required,
    helperText,
    onChange,
    onBlur,
  } = props;

  const intl = useIntl();
  const value = useMemo(() => {
    if (
      propsValue == null ||
      !values.find((option) => option.id === propsValue)
    ) return '';

    return propsValue;
  }, [values, propsValue]);

  return (
    <FormControl fullWidth error={error}>
      <Select
        data-testid="employee-select-field"
        fullWidth
        variant="outlined"
        IconComponent={ArrowDown}
        inputProps={{ 'data-testid': 'employee-select-field-input' }}
        className={clsx(styles.container, { [styles.required]: required })}
        value={value}
        onChange={(event) => onChange?.(event.target.value !== '' ? event.target.value : null)}
        disabled={disabled}
        displayEmpty
        required={required}
        onBlur={onBlur}
      >
        <MenuItem data-testid="select-employee-any" value="" key="employee-select-field-empty" selected>
          <Stack direction="row" alignItems="center">
            <Avatar className={styles.avatar} />
            <Typography className={styles.label}>
              {intl.formatMessage({ id: 'appointment.anyAvailableEmployee' })}
              {required && '*'}
            </Typography>
          </Stack>
        </MenuItem>

        {values.map((valueItem) => (
          <MenuItem data-testid={`select-employee-${valueItem.id}`} value={valueItem.id} key={`employee-select-field-${valueItem.id}`}>
            <Stack direction="row" alignItems="center">
              <Avatar className={styles.avatar}>
                <Typography className={styles.avatarText}>{valueItem.iconLabel}</Typography>
              </Avatar>
              <Typography className={styles.label}>{valueItem.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}

EmployeeSelectFieldComponent.defaultProps = {
  disabled: false,
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  required: false,
  helperText: undefined,
};

export const EmployeeSelectField = React.memo(EmployeeSelectFieldComponent);
