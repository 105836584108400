import React from 'react';

import { FormField, TextField, Nullable } from '@linetweet/linetweet-ui';

import styles from './OutlinedInput.module.scss';

interface OutlinedInputProps {
  onChange: (value: Nullable<string>) => void;
  value?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  multiline?: boolean;
  disabled?: boolean;
  required?: boolean;
  onBlur?: () => void;
  error?: boolean;
  dataTestId?: string;
}

export function OutlinedInput(props: OutlinedInputProps) {
  const { name, multiline, value, label, placeholder, type, onChange, disabled, required, error, onBlur, dataTestId } = props;

  return (
    <FormField name={name} fullWidth>
      <TextField
        dismissKeyboardOnReturn
        multiline={multiline || false}
        value={value}
        label={label}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        required={required}
        error={error}
        onBlur={onBlur}
        autoComplete="none"
        inputProps={{ 'data-testid': dataTestId }}
        InputLabelProps={{ shrink: true }}
        className={`${styles.input} ${multiline ? styles.multiline : ''}`}
        maxRows={2}
        onClick={() => undefined}
        onKeyDown={() => undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value || null);
        }}
      />
    </FormField>
  );
}

OutlinedInput.defaultProps = {
  label: undefined,
  type: 'text',
  value: '',
  name: undefined,
  multiline: false,
  placeholder: undefined,
  disabled: undefined,
  required: undefined,
  error: undefined,
  onBlur: undefined,
};
