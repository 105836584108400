export default {
  'common.formHasErrors': 'Veuillez vérifier le formulaire pour les champs obligatoires',
  'common.from': 'De',
  'common.to': 'A',
  'common.today': "Aujourd'hui",
  'common.more': 'Plus',
  'common.editEntity': 'Modifier',
  'common.abort': 'Annuler',
  'common.delete': 'Supprimer',
  'common.language': 'Sélectionner la langue',
  'common.defaultMenuTitle': 'Rendez-vous',
  'common.switchNdl': 'Сhanger de magasin',
  'common.signOut': 'Déconnexion',
  'common.defaultStoreSettings': 'Paramètres magasin',
  'common.save': 'Enregistrer',
  'common.confirm': 'Confirmer',
  'common.error': 'Erreur',
  'common.defaultError': "Une erreur s'est produite",
  'common.defaultErrorDescription': 'Videz le cache et rafraîchissez',
  'common.createAppointmentError': "Une erreur s'est produite lors de la création du rendez-vous",
  'common.createAppointmentErrorDescription': "Le rendez-vous n'a pas été créé. Veuillez vérifier votre connexion Internet",
  'common.deleteAppointmentError': "Une erreur s'est produite lors de la tentative de suppression du rendez-vous",
  'common.deleteAppointmentErrorDescription': "Le rendez-vous n'a pas été supprimé. Veuillez vérifier votre connexion Internet",
  'common.appVersion': 'Version',
  'common.minutesShort': '{minutes} min',
  'common.gender.none': '-',
  'common.gender.male': 'Monsieur',
  'common.gender.female': 'Madame',
  'common.search': 'Rechercher',
  'common.appIsOfflineTitle': 'Attention : l\'application sur cet appareil est désormais hors ligne !',
  'common.appIsOfflineDescription': 'La synchronisation n\'est pas possible sur cet appareil. Veuillez vérifier la connexion Internet.',
  'common.appIsOnlineTitle': 'L\'application est à nouveau en ligne.',
  'common.appIsOnlineDescription': 'La synchronisation va continuer.',
  'common.appIsDisconnectedTitle': 'L\'application est hors ligne. Un rechargement est nécessaire.',
  'common.appIsDisconnectedDescription': 'L\'application doit être rechargée une fois pour garantir que les données sont' +
    ' traitées correctement.',
  'common.appIsDisconnectedAction': 'Recharger l\'application',
  'filter.title': 'Se concentrer sur',
  'appointment.copyToNew': 'Copier les informations dans un nouvel événement',
  'appointment.information': 'Informations',
  'appointment.recurringEvent': 'Rendez-vous récurrent',
  'appointment.recurringEventPlaceholder': 'Pas récurrent',
  'appointment.lastName': 'Nom',
  'appointment.firstName': 'Prénom',
  'appointment.email': 'Adresse e-mail',
  'appointment.phoneNumber': 'N° de téléphone',
  'appointment.notes': 'Remarques',
  'appointment.title': 'Civilité',
  'appointment.preferredTimes': 'Recommandations horaires',
  'appointment.availableTimes': 'Horaires disponibles',
  'appointment.showPreferred*': 'Recommandations uniquement',
  'appointment.showAll': 'Afficher tous les horaires',
  'appointment.swapTimes': 'Mehr Optionen',
  'appointment.swapTimesDescription': 'Eine rechenintensive Prüfung des Lösungsraums kann zu weiteren Terminoptionen führen. Damit werden Termine für spezifische Mitarbeiter ermöglicht, indem Termine unter Mitarbeitern getauscht werden.',
  'appointment.swapTimesEmployeeNotSelected': 'Um mehr Optionen zu prüfen, wähle einen Mitarbeiter aus.',
  'appointment.swapTimesFetch': 'Mehr Optionen prüfen',
  'appointment.swapTimesEmpty': 'Keine weiteren Optionen verfügbar',
  'appointment.appointment': 'Rendez-vous',
  'appointment.walkin': 'Clients sans rendez-vous',
  'appointment.task': 'Tâche',
  'appointment.break': 'Pause',
  'appointment.blocker': 'Bloqueur',
  'appointment.customer': 'Client',
  'appointment.specificEmployee': 'attribuer à...',
  'appointment.date': 'Date',
  'appointment.service': 'Service après-vente',
  'appointment.selectAllServices': 'Tout sélectionner',
  'appointment.unselectAllServices': 'tout déselectionner',
  'appointment.anyAvailableEmployee': 'Tout employé disponible',
  'language:dialogTitle': 'Sélectionner la langue',
  'language.german': 'Deutsch',
  'language.english': 'English',
  'language.french': 'Français',
  'service.visionTest': 'Contrôle de la vue',
  'service.advisory': 'Conseil',
  'service.sunglasses': 'Lunettes de soleil',
  'service.driverVisionTest': 'Contrôle de la vue pour un PC',
  'service.pickup': 'Retrait',
  'service.service': 'Service après-vente',
  'service.workshop': 'Atelier',
  'service.serviceWorkshop': 'Réparation, ajustement, nettoyage',
  'service.eyeCheckUp': 'Bilan de santé oculaire par télémédecine',
  'service.augen': 'Bilan de santé oculaire par télémédecine',
  'service.KLAdjustment': 'Adaptation de lentilles de contact',
  'service.KLFollowUpInspection': 'Recontrôle des lentilles de contact',
  'service.serviceHA': 'Services appareils auditifs',
  'service.firstConsultation': 'Première consultation',
  'service.shortHearingTest': 'Court test auditif',
  'service.followUpAppointment': 'Visite de suivi',
  'service.submissionCompletion': 'Remise des lentilles',
  'service.postControl': 'Contrôle de suivi',
  'service.adultPostControl': 'Contrôle de suivi approfondi',
  'service.earProtection': 'Protection auditive',
  'service.orderOptimization': 'Optimisation de l’ordre',
  'service.other': 'Autres',
  'service.BO': 'BO',
  'service.KL': 'LC',
  'service.HA': 'AA',
  'service.followUpFitting': 'Rendez vous suivi de confort',
  'service.videoConsultation': 'Consultation vidéo',
  'service.consultation': 'Consultation',
  'menu.operations': 'Opérations',
  'menu.performance': 'Performance',
  'menu.appointments': 'Rendez-vous',
  'menu.confirmEpepResyncTitle': 'Confirmer la synchronisation avec le logiciel de shift planning',
  'menu.confirmEpepResyncContent': "Veuillez confirmer la synchronisation de l'EPEP",
  'menu.confirmBreaksResyncTitle': 'Pousser les pauses vers DiWa',
  'menu.confirmBreaksResyncContent': 'Merci de confirmer la synchronisation des pauses',
  'menu.epepSync': 'Rafraîchir les informations de planning du personnel',
  'menu.epepSyncDescription':
    'Cliquer sur ce bouton pour mettre a jour les changements depuis votre logiciel de planning du personnel vers LineTweet.',
  'menu.breaksSync': 'Pauses de synchronisation',
  'menu.breaksSyncDescription': 'Cliquez ici si vous souhaitez ecraser la planification des pauses dans DiWa par les pauses LTB',
  'calendarOverview.overflowItems': 'Conflit',
  'calendarOverview.employees': 'Collaborateurs',
  'calendarOverview.noEvents': 'Aucun rendez-vous',
  'resources.EyeTestRooms': 'Salle de EV',
  'resources.ContactLenses': 'Salle de LC',
  'resources.Horgerate': 'Salle de AA',
  'calendar.employee': 'Collaborateur',
  'calendar.employeeWorkingHours': 'Horaires: {from} à {to}',
  'calendar.appointments': 'Rendez-vous',
  'calendar.blockers': 'Bloqueurs',
  'calendar.breaks': 'Pause',
  'calendar.tasks': 'Tâches',
  'calendar.slots': 'Creneau horaire',
  'calendar.walkin': 'Client sans RV',
  'calendar.deleteAppoinctmentDialog': 'Voulez-vous vraiment supprimer ce rendez-vous? Cette action ne peut pas être annulée.',
  'calendar.deleteAppointmentDialogTitle': 'Confirmation',
  'calendar.walkinTitle': 'Réservé aux clients sans rendez-vous',
  'calendar.reserveTitle': 'Réservé',
  'calendar.createAppointment': 'Créer un rendez-vous',
  'calendar.appointmentIsDeleting': 'Cet événement sera supprimé',
  'calendar.appointmentIsDeletingDescription': 'Mise à jour en cours',
  'calendar.submit': 'Appliquer',
  'calendar.storeNotWorking': 'Le magasin est fermé ce jour',
  'calendar.overflow': 'Débordement',
  'search.title': 'Recherche',
  'search.noResults': 'Aucun résultat',
  'search.noName': 'Aucun nom',
  'calendarEvent.employee.noSlotsAvailableError': 'Il n’y a pas d’emplacements disponibles pour l’employe selectionne',
  'userAgent.notCompatibleTitle': 'Not compatible',
  'userAgent.notCompatibleDescription': 'Your current browser is not compatible with LineTweet Booking (LTB). Please use Edge',
  'userAgent.notCompatibleTip': 'Use Edge',
  'slot.slotsFetchErrorTitle': 'Erreur lors de la récupération des créneaux horaires, veuillez actualiser',
};
