import React from 'react';
import { useIntl } from 'react-intl';
import { Notice, NoticeStackGroup } from '@linetweet/linetweet-ui';

type ConnectionStatusNoticeProps = {
  isConnectionOfflineOpen: boolean;
  isConnectionOnlineOpen: boolean;
  onClose: () => void;
};

export function ConnectionStatusNotice({
  isConnectionOfflineOpen,
  isConnectionOnlineOpen,
  onClose,
}: ConnectionStatusNoticeProps) {
  const intl = useIntl();
  return (
    <NoticeStackGroup>
      <Notice
        open={isConnectionOfflineOpen}
        severity="error"
        title={intl.formatMessage({ id: 'common.appIsOfflineTitle' })}
        description={intl.formatMessage({ id: 'common.appIsOfflineDescription' })}
      />

      <Notice
        open={isConnectionOnlineOpen}
        onClose={onClose}
        severity="success"
        title={intl.formatMessage({ id: 'common.appIsOnlineTitle' })}
        description={intl.formatMessage({ id: 'common.appIsOnlineDescription' })}
      />
    </NoticeStackGroup>
  );
}
