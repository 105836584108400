import {
  Appointment,
  CalendarEventTypeEnum,
  Service,
  Slot,
} from 'types';

import { IOption, Nullable } from '@linetweet/linetweet-ui';
import { GetAppointmentCustomer, GetAppointmentServices } from 'features/commons';
import { TimeHelper } from '../../utils';
import { AppointmentFormSlotItem, CalendarEventCreateParams } from './types';

export function createSlotOptions(params: {
  timeZone: string;
  slots: Slot[];
  employeeId?: Nullable<string>;
  defaultSlot?: AppointmentFormSlotItem;
}): IOption<number>[] {
  const currentDate = TimeHelper.toDayjs(Date.now(), params.timeZone);
  const currentDateString = currentDate.format('YYYY-MM-DD');
  const currentTimeMinutes = TimeHelper.getMinutesFromHHmmTimeString(currentDate.format('HH:mm'));

  const slotSet = new Set<string>();
  const slotOptions: IOption<number>[] = [];

  for (let slotIndex = 0; slotIndex < params.slots.length; slotIndex += 1) {
    const slot = params.slots[slotIndex];
    const employeeIdFits = !params.employeeId || slot.employeeId === params.employeeId;

    if (employeeIdFits) {
      for (let timeSlotIndex = 0; timeSlotIndex < slot.timeSlots.length; timeSlotIndex += 1) {
        const timeSlot = slot.timeSlots[timeSlotIndex];
        const value = TimeHelper.getMinutesFromHHmmTimeString(timeSlot);
        const slotFits = slot.date === currentDateString ? value >= currentTimeMinutes : true;

        if (slotFits && !slotSet.has(timeSlot)) {
          slotSet.add(timeSlot);
          slotOptions.push({
            label: timeSlot,
            value,
          });
        }
      }
    }
  }

  if (params.defaultSlot) {
    if (!slotOptions.find((option) => option.value === params.defaultSlot?.value)) {
      slotOptions.push(params.defaultSlot);
    }
  }

  return slotOptions.sort((optionA, optionB) => optionA.value - optionB.value);
}

export function getDateRange(date: string, timeZone?: string): string[] {
  const currentDay = TimeHelper.toDayjs(new Date(), timeZone);
  let startOfRange = TimeHelper.toDayjs(date, timeZone);

  if (startOfRange.isSame(currentDay, 'month')) {
    // if date in current month, we start from current day to not show previous months
    startOfRange = currentDay.clone();
  } else {
    // otherwise start with month start
    startOfRange = startOfRange.startOf('month');
  }
  const days: string[] = [];
  for (let i = 0; i < 30; i += 1) {
    days.push(TimeHelper.toStandardFormat(startOfRange.add(i, 'days')));
  }
  return days;
}

export const calendarEventToCalendarCreateValues = (
  calendarEvent: Appointment,
  allServices: Service[],
  employeeId: string | undefined,
  timezone: string,
): CalendarEventCreateParams => {
  const customer = GetAppointmentCustomer(calendarEvent);
  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);
  return {
    type: calendarEvent.type as unknown as CalendarEventTypeEnum,
    date: TimeHelper.getDateStringTz(new Date(), timezone),
    note: calendarEvent.note || null,
    gender: customer?.gender || null,
    services,
    duration: calendarEvent.duration || null,
    employeeId: employeeId || null,
    title: calendarEvent.title || null,
    lastName: customer?.lastName || '',
    firstName: customer?.name || '',
    email: customer?.email || '',
    phoneNumber: customer?.phoneNumber || '',
    recurring: null,
    from: calendarEvent.time,
    to: calendarEvent.time + (calendarEvent.duration || 0),
  };
};
