import React from 'react';
import { FormField, TextField, Nullable } from '@linetweet/linetweet-ui';
import clsx from 'clsx';

import styles from './CustomerNameInput.module.scss';

interface CustomerNameInputProps {
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
  label?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: () => void;
  dataTestId?: string;
}

export function CustomerNameInput(
  {
    name,
    label,
    value,
    onChange,
    placeholder,
    disabled,
    required,
    error,
    helperText,
    onBlur,
    dataTestId,
  }: CustomerNameInputProps,
) {
  return (
    <FormField name={name} fullWidth>
      <TextField
        disabled={disabled}
        required={required}
        dismissKeyboardOnReturn
        inputProps={{ 'data-testid': dataTestId }}
        value={value || ''}
        label={label}
        placeholder={placeholder}
        className={clsx(styles.input, { [styles.required]: required })}
        InputLabelProps={{ shrink: true }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
      />
    </FormField>
  );
}

CustomerNameInput.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
  disabled: undefined,
  required: undefined,
  error: undefined,
  helperText: undefined,
  onBlur: undefined,
};
