import React, { useCallback, useMemo, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClickAwayListener, styled } from '@mui/material';

import { ToggleButton } from '@linetweet/linetweet-ui';

import { TimeHelper } from 'utils';
import styles from './FormDatePicker.module.scss';

interface HighlightedPickersDayProps extends PickersDayProps<Dayjs> {
  isHighlighted: boolean;
}

type Props = {
  date: string;
  suggestedDays?: string[];
  onChange: (value: string) => void;
  isDisabled?: boolean;
};

const HighlightedPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<HighlightedPickersDayProps>(({ theme, isHighlighted }) => ({
  ...(isHighlighted && {
    backgroundColor: 'rgba(100, 100, 100, 0.8)',
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: 'rgba(100, 100, 100, 0.9)',
    },
  }),
})) as React.ElementType<HighlightedPickersDayProps>;

// eslint-disable-next-line react/require-default-props
export function CustomDay(props: PickersDayProps<Dayjs> & { suggestedDays?: string[] }) {
  const { suggestedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted =
    !outsideCurrentMonth &&
    suggestedDays.includes(day.format('YYYY-MM-DD'));

  return (
    <HighlightedPickersDay
      {...other}
      data-testid={`date-picker-day-${TimeHelper.toDayFormat(day)}`}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      isHighlighted={isHighlighted}
    />
  );
}

export function FormDatePicker({ date, onChange, suggestedDays, isDisabled }: Props) {
  const [isOpen, setIsDatePickerOpen] = useState(false);
  const onOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // prevent triggering onClick event when used as child of ToggleButtonGroup
    event.stopPropagation();
    event.preventDefault();
    setIsDatePickerOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);

  const datePickerValue = useMemo(() => {
    if (!date) {
      return '';
    }
    return TimeHelper.toDayjs(date).format('dddd, MMM D');
  }, [date]);

  return (
    // close picker on click outside
    // https://github.com/mui/material-ui/issues/25135#issuecomment-1552264673
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <Box position="relative">
        <DesktopDatePicker // @ts-ignore
          PaperProps={{ 'data-testid': 'calendar-event-date-picker-paper' }}
          open={isOpen}
          onClose={onClose}
          inputFormat="D MMM YYYY"
          disablePast
          disabled={!!isDisabled}
          value={dayjs(date)}
          onChange={(newDate) => {
            if (newDate) {
              onChange(newDate.format('YYYY-MM-DD'));
            }
          }}
          disableMaskedInput
          renderDay={(_, __, pickersDayProps) => <CustomDay suggestedDays={suggestedDays} {...pickersDayProps} />}
          renderInput={(props) => (
            <ToggleButton
              data-testid="form-date-picker-button"
              variant="rounded"
              color="secondary"
              value=""
              ref={props.inputRef}
              className={styles.renderValue}
              disabled={!!isDisabled}
              onClick={onOpen}
            >
              {datePickerValue}
            </ToggleButton>
          )}
        />
      </Box>
    </ClickAwayListener>
  );
}

FormDatePicker.defaultProps = {
  suggestedDays: undefined,
  isDisabled: undefined,
};
