import { DialogSection } from '@linetweet/linetweet-ui';
import React from 'react';
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CustomerNameInput, OutlinedInput } from '../subcomponents';

type Props = {
  titleDisabled?: boolean;
  isDisabled?: boolean;
};

function AppointmentFormInformationSectionComponent({ titleDisabled, isDisabled }: Props) {
  const form = useFormContext();
  const intl = useIntl();

  return (
    <DialogSection
      title={intl.formatMessage({ id: 'appointment.information' })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState }) => (
              <CustomerNameInput
                dataTestId="title-input"
                placeholder={intl.formatMessage({ id: 'appointment.title' })}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={titleDisabled || !!isDisabled}
                required
                error={!!fieldState.error}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="note"
            render={({ field }) => (
              <OutlinedInput
                dataTestId="break-blocker-notes-input"
                placeholder={intl.formatMessage({ id: 'appointment.notes' })}
                disabled={!!isDisabled}
                value={field.value || ''}
                multiline
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </DialogSection>
  );
}

AppointmentFormInformationSectionComponent.defaultProps = {
  titleDisabled: false,
  isDisabled: false,
};

export const AppointmentFormInformationSection = React.memo(AppointmentFormInformationSectionComponent);
