import { Middleware } from '@reduxjs/toolkit';
import { socketIo } from '../../utils/socketIo';
import { getUser, getUserSettings } from '../user/thunks';
import { redirectToLoginPage } from '../../utils/user';
import { getStore } from '../store/thunks';
import { onAppIsOffline, onAppIsOnline } from '../global/slice';
import { updateShifts } from '../employees/slice';
import { setCalendarEvents } from '../appointments/slice';
import { updateEventStats } from '../eventStats/slice';
import { getVersion } from '../health/thunks';
import { DocumentsQueue } from './documentsQueue';
import { ExternalAppointment } from '../../types';
import { syncSlots } from '../calendarSlots/slice';

type AppointmentDocumentsQueueType = ExternalAppointment;
type SlotDocumentsQueueType = {
  timestamp: number,
  items: any[],
};

export const SocketIoMiddleware: Middleware<{}, any> = (api) => (next) => (action) => {
  if (action.type === 'global/connect') {
    if (!api.getState().global.connectionStatus) {
      const appointmentDocumentsQueue = new DocumentsQueue<AppointmentDocumentsQueueType, string>(
        (data) => {
          api.dispatch(setCalendarEvents({
            data,
            timeZone: api.getState().store.data.timezone,
          }));
        },
      );

      const slotDocumentsQueue = new DocumentsQueue<SlotDocumentsQueueType, any>(
        (data) => {
          api.dispatch(syncSlots({
            data,
            timeZone: api.getState().store.data.timezone,
          }));
        },
      );

      socketIo.on('connect', async () => {
        console.log('connected');

        await api.dispatch(getUser() as any);
        await api.dispatch(getStore() as any);
        await api.dispatch(getUserSettings() as any);
        await api.dispatch(getVersion() as any);
        api.dispatch(onAppIsOnline() as any);
      });

      socketIo.on('disconnect', (status, details) => {
        // the reason of the disconnection, for example "transport error"
        console.log(status);

        // the low-level reason of the disconnection, for example "xhr post error"
        // @ts-ignore
        console.log(details?.message);

        // some additional description, for example the status code of the HTTP response
        // @ts-ignore
        console.log(details?.description);

        // some additional context, for example the XMLHttpRequest object
        // @ts-ignore
        console.log(details?.context);

        if (status === 'io server disconnect') {
          redirectToLoginPage();
        } else {
          api.dispatch(onAppIsOffline() as any);
        }
      });

      socketIo.on('connect_error', (data) => {
        console.log(`Disconnected w/ connect_error: ${data}`);
        if (data.message === 'unauthorized') {
          redirectToLoginPage();
        }

        if (data.message === 'xhr poll error' || data.message === 'websocket error') {
          api.dispatch(onAppIsOffline() as any);
        }
      });

      socketIo.on('error', (error) => {
        console.log(`error : ${error}`);
        api.dispatch(onAppIsOffline() as any);
      });

      socketIo.on('calendar-events-synced', (data) => {
        appointmentDocumentsQueue.addBulkDocuments({
          set: [...data.update, ...data.create],
          remove: data.delete,
        });
      });

      socketIo.on('calendar-event-added', (data) => {
        appointmentDocumentsQueue.addDocumentToSet(data);
      });

      socketIo.on('calendar-event-updated', (data) => {
        appointmentDocumentsQueue.addDocumentToSet(data);
      });

      socketIo.on('calendar-event-deleted', (data) => {
        appointmentDocumentsQueue.addDocumentToRemove(data.id);
      });

      socketIo.on('event-stats-updated', (data) => {
        api.dispatch(updateEventStats(data) as any);
      });

      socketIo.on('shifts-updated', (data) => {
        api.dispatch(updateShifts({
          shifts: data.shifts,
          date: data.date,
        }));
      });

      socketIo.on('slot-deleted', async (data) => {
        slotDocumentsQueue.addDocumentToRemove(data);
      });

      socketIo.on('slots-synced', (data) => {
        slotDocumentsQueue.addBulkDocuments({
          set: [{
            timestamp: data.timestamp,
            items: [...data.create, ...data.update],
          }],
          remove: data.delete,
        });
      });

      socketIo.connect();
    }
  }

  if (action.type === 'global/onAppIsStopped') {
    console.log('App is stopped');
    socketIo.disconnect();
  }

  next(action);
};
