import { io } from 'socket.io-client';

export const socketIo = io(`${process.env.REACT_APP_API_BASE_URL}/lt-booking`, {
  path: `${process.env.REACT_APP_API_PATH}/socket.io/`,
  withCredentials: true,
  autoConnect: false,
});

export async function requestData<T>(eventName: string, data?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    socketIo.timeout(15000).emit(eventName, data, (error, data) => {
      if (error) {
        console.log(`socket.requestData Eror: ${error}`);
        reject(error);
        return;
      }

      if (data && data?.error) {
        console.log(`socket.requestData data.error: ${data?.error}`);
        reject(new Error(`Cannot get data from the server: ${eventName}, error: ${data.error}`));
        return;
      }

      resolve(data as T);
    });
  });
}
